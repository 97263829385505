import loadable from "@loadable/component"
import React from "react"
import styled from "styled-components"
import { OverviewArray } from ".."
import BaseWrapper from "../wrapper"

const Large = loadable(() => import("./large"))
const Medium = loadable(() => import("./medium"))

interface IProps {
  data: OverviewArray
  large?: boolean | undefined
}

const width = "1440px"

const Wrapper = styled(BaseWrapper)`
  @media (min-width: ${width}) {
    max-width: ${width};
  }
`

const Employee: React.FC<IProps> = ({ data, large }) => {
  return (
    <Wrapper>{large ? <Large data={data} /> : <Medium data={data} />}</Wrapper>
  )
}

export default Employee
